import * as React from "react";
import { graphql } from "gatsby";
import Image from "gatsby-plugin-sanity-image";
import styled from "styled-components";
import Layout from "../components/layout";

const SpecialsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  grid-gap: 10px;
`;

const SpecialContainer = styled.div`
  flex-basis: 45%;
  position: relative;
  margin-bottom: 30px;

  h3 {
    position: absolute;
    top: 0px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    font-size: 22px;
    padding: 5px 0px;
    text-align: center;
  }

  div {
    position: absolute;
    bottom: 0px;
    color: white;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    font-size: 18px;
    padding: 5px 0px;
    text-align: center;
  }

  .specialImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
    object-position: 50% 50%;
  }

  @media only screen and (max-width: 600px) {
    flex-basis: 90%;
  }
`;

// markup
const SpecialsPage = (data) => {
  return (
    <Layout title={"Specials"}>
      <SpecialsContainer>
        {data.data.allSanitySpecial.nodes.map((special) => (
          <SpecialContainer>
            <h3>{special.name}</h3>
            <Image
              className="specialImage"
              {...special.image}
              alt={special.name}
            />
            <div>{special.description}</div>
          </SpecialContainer>
        ))}
      </SpecialsContainer>
    </Layout>
  );
};

export const query = graphql`
  query SpecialsQuery {
    allSanitySpecial {
      nodes {
        description
        name
        image {
          ...ImageWithPreview
        }
      }
    }
  }
`;

export default SpecialsPage;
